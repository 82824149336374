import axios from 'axios';
import { parse } from 'marked';
import React, { Suspense, useState } from 'react';
import './App.css';

const CloudflareAI = () => {
  const [uuid, setUuid] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [responseMessages, setResponseMessages] = useState([]);
  let apiUrl = "https://mindful-llama.kipper.workers.dev/";

  const setApiUrl = () => {
    const customUrl = prompt("Enter custom API URL: ");
    if (customUrl) {
      apiUrl = customUrl;
    }
  }

  const setIDSession = () => {
    const customUUID = prompt("Enter custom session ID: ");
    if (customUUID) {
      setUuid(customUUID);
    }
  }

  const generateUUID = () => {
    let newUUID = '';
    const chars = 'abcdef0123456789';
    for (let i = 0; i < 32; i++) {
      const charIndex = Math.floor(Math.random() * chars.length);
      newUUID += chars[charIndex];
      if (i === 7 || i === 11 || i === 15 || i === 19) {
        newUUID += '-';
      }
    }
    return newUUID;
  }

  const chat = async () => {
    const encodedInput = encodeURIComponent(inputValue);
    console.log(apiUrl)
    const userMessageDiv = <div className="user-message">{decodeURIComponent(encodedInput)}</div>;
    setResponseMessages([...responseMessages, userMessageDiv]);

    try {
      const response = await axios.post(`https://ai-creative.ashishpatel.workers.dev`, {text:encodedInput});
      const aiMessages = response.data;
      const aiMessagesDiv = []
      if (aiMessages.length > 0) {
        aiMessages.forEach(message => {
          console.log(message);
          const aiMessageDiv = <div className="ai-message" dangerouslySetInnerHTML={{ __html: parse(message.response) }}></div>;
          aiMessagesDiv.push(aiMessageDiv);
        });
        //const lastAiMessage = aiMessages[aiMessages.length - 1];
      }
      setResponseMessages([...responseMessages, userMessageDiv, ...aiMessagesDiv]);
      setInputValue('');
    } catch (error) {
      console.log("Error receiving response:", error);
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    chat();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      chat();
    }
  }

  if (!uuid) {
    setUuid(generateUUID());
  }

  return (
    <>
      <div className="dropdown">
        <button className="dropbtn">Settings</button>
        <div className="dropdown-content">
          <a href="#" onClick={setApiUrl}>Custom API URL</a>
          <a href="#" onClick={setIDSession}>Custom ID Session</a>
        </div>
      </div>
      <div id="response">
        {responseMessages.map((message, index) => (
          <React.Fragment key={index}>
            {message}
          </React.Fragment>
        ))}
      </div>
      <form id="chatForm mt-20 text-black text-xl border-2 border-gray-600" onSubmit={handleFormSubmit}>
        <textarea id="input" type="text" className="text-gray-500 border-2 border-gray-600" placeholder="Send a message" value={inputValue} onChange={handleInputChange} onKeyDown={handleKeyDown}></textarea>
        <button type="submit">➤</button>
      </form>
      <a className="fa fa-github github-icon" href="https://github.com/localuser-isback/Cloudflare-AI" style={{ fontSize: '36px' }}></a>
    </>
  );
}

const CloudflareAIWithSuspense = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <CloudflareAI />
  </Suspense>
);

export default CloudflareAIWithSuspense;
